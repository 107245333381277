module.exports = {
  locales: ['en', 'pt', 'fr', 'es', 'it', 'nb', 'da', 'de', 'nl'],
  defaultLocale: 'en',
  id: 7,
  logo: {
    sources: [
      {
        media: '(max-width: 1024px)',
        srcSet: `${process.env.PUBLIC_PREFIX}/svg/tenants/7/misterauto_bparts_logo_mobile.svg`,
      },
    ],
    img: `${process.env.PUBLIC_PREFIX}/svg/tenants/7/misterauto_bparts_logo.svg`,
  },
  font: {
    name: 'Open Sans',
    url: 'https://fonts.googleapis.com/css?family=open+Sans:300,600&display=swap',
  },
  brandName: 'Mister-Auto',
  mainSite: {
    en: 'https://www.mister-auto.ie/',
    pt: 'https://www.mister-auto.pt/',
    fr: 'https://www.mister-auto.com/',
    es: 'https://www.mister-auto.es/',
    it: 'https://www.mister-auto.it/',
    nb: 'https://www.mister-auto.no/',
    da: 'https://www.mister-auto.dk/',
    de: 'https://www.mister-auto.de/',
    nl: 'https://www.mister-auto.nl/',
  },
};
