import cx from 'classnames';
import { atom, useAtom } from 'jotai';
import { FunctionComponent, HTMLAttributes } from 'react';

import { showLoading } from '../next-loading';

export const footerCurve = atom<TPageConfig['footerCurve']>('PRIMARY');

import classes from './index.module.css';

const getVariant: Function = (variant: string) => {
  switch (variant) {
    case 'SECONDARY':
      return 'bg-neutral-10';
    case 'TRANSPARENT':
      return '';
    case 'PRIMARY':
    default:
      return 'bg-white';
  }
};

const FooterUpperCurve: FunctionComponent<{
  variant?: TPageConfig['footerCurve'];
}> = ({ variant }) => {
  const [show] = useAtom(showLoading);
  const [variantJotai] = useAtom(footerCurve);

  if (variant === 'NONE') return null;

  return (
    <div
      className={cx(
        getVariant(variant || variantJotai),
        'relative',
        classes.footerUpperCurve
      )}
    >
      <div className={cx('relative', { 'z-50': show })}>
        <Desktop className="hidden lg:block" />
        <Mobile className="lg:hidden" />
      </div>
    </div>
  );
};

export default FooterUpperCurve;

const Mobile: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 375 84"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375 78.5239V84H0V50.438L161.881 6.99208C243.228 -14.8399 326.275 15.9476 375 78.5239Z"
      fill="currentcolor"
    />
  </svg>
);

const Desktop: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 1194 68"
    fill="none"
    preserveAspectRatio="none"
    className={cx([props.className], 'w-full', 'h-16')}
  >
    <path
      d="M945.075 1.59148e-05C1032 0.013331 1117.25 8.38141 1200 24.3918V69H0V69L841.473 3.92895C876.092 1.30049 910.505 0.00524784 944.659 1.59148e-05C944.798 -5.30542e-06 944.936 -5.30446e-06 945.075 1.59148e-05Z"
      fill="currentcolor"
    />
  </svg>
);
