import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import cookie from 'js-cookie';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  useContext,
  createContext,
  useState,
  useEffect,
  FunctionComponent,
  ReactNode,
} from 'react';

import tenantConfig from '../../../../config/tenants';
import { fetchUserShippingLocation } from '../../../services/shipping-locations/list';
import {
  marktingAtom,
  preferencesAtom,
  statisticsAtom,
  useUpdateConcentAtom,
  requiredAtom,
} from '../../../state/application';
import { SHIPPING_LOCATION } from '../../../static/cookies';

const { publicRuntimeConfig } = getConfig();

const appContext = createContext<{
  // features
  features: {
    isBrandEnabled: boolean;
  };
  isKBA: boolean;
  isPlate: boolean;
  shippingLocation: number | null;
  concent: {
    preferences: boolean | null;
    markting: boolean | null;
    statistics: boolean | null;
    required: boolean | null;
  };
  tenantConfig: Tenant;
  isBparts: boolean;
  brand?: string;
  brandName?: string;
}>({
  features: {
    isBrandEnabled: publicRuntimeConfig.isFeatureBrandEnabled,
  },
  isKBA: false,
  isPlate: false,
  shippingLocation: null,
  concent: {
    preferences: false,
    markting: false,
    statistics: false,
    required: false,
  },
  tenantConfig: tenantConfig,
  isBparts: false,
  brand: publicRuntimeConfig.brand || null,
  brandName: publicRuntimeConfig.brandName || null,
});

const getIsKBA = (shippingLocation: number) => shippingLocation === 13;
const getIsPlate = (shippingLocation: number) =>
  [1, 2, 3, 4, 5, 6, 8, 11, 14, 15, 19, 22, 23, 24, 26, 28].indexOf(
    shippingLocation
  ) >= 0;

export const AppContextProvider: FunctionComponent<{
  shippingLocation: number;
  children: ReactNode;
}> = ({ shippingLocation, children }) => {
  const { locale = 'en' } = useRouter();
  const [shipping, setShipping] = useState(shippingLocation);

  const preferences = useAtomValue(preferencesAtom);
  const markting = useAtomValue(marktingAtom);
  const statistics = useAtomValue(statisticsAtom);
  const required = useAtomValue(requiredAtom);
  const triggerConcentUppdated = useUpdateConcentAtom();

  const client = useQueryClient();

  useEffect(() => {
    triggerConcentUppdated();
    if (!shipping) {
      const clientShipping = parseInt(cookie.get(SHIPPING_LOCATION) || '0', 10);

      if (!clientShipping) {
        client
          .fetchQuery(['user-shipping', locale], ({ queryKey: [, lng] }) =>
            fetchUserShippingLocation({ locale: lng })
          )
          .then((data) => {
            setShipping(data.id);
          });

        // TODO PROBABLY MAKE ERROR IF NO SHIPPING LOCATION
      }
      setShipping(clientShipping);
    }
  }, []);

  return (
    <appContext.Provider
      value={{
        features: {
          isBrandEnabled: publicRuntimeConfig.isFeatureBrandEnabled,
        },
        isKBA: getIsKBA(shipping),
        isPlate: getIsPlate(shipping),
        shippingLocation: shipping,
        concent: {
          preferences,
          markting,
          statistics,
          required,
        },
        tenantConfig: tenantConfig,
        isBparts: process.env.NEXT_PUBLIC_TENANT === '1',
        brand: publicRuntimeConfig.brand,
        brandName: publicRuntimeConfig.brandName,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => useContext(appContext);
